import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "./firebase";

import "./validation";

import VueScrollTo from "vue-scrollto";
import VueHtml2Canvas from "vue-html2canvas";
import VueHtml2pdf from "vue-html2pdf";
import VueAnime from "vue-animejs";
import VueGoogleCharts from "vue-google-charts";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faTrashAlt,
  faPen,
  faTimes,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./styles.css";

Vue.use(VueScrollTo);
Vue.use(VueHtml2Canvas);
Vue.use(VueHtml2pdf);
Vue.use(VueAnime);
Vue.use(VueGoogleCharts);

library.add(faPlus, faTrashAlt, faPen, faTimes, faMinus);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("fetchUserData", user);
  }
});
