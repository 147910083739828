<template>
  <figure class="logo-container">
    <img
      v-if="color === 'black'"
      src="../assets/logo/elha-logo-black.svg"
      alt="ELHA logo"
      :class="sizeClass"
    />
    <img
      v-else-if="color === 'white'"
      src="../assets/logo/elha-logo-white.svg"
      alt="ELHA logo"
      :class="sizeClass"
    />
    <img
      v-else-if="color === 'green-white'"
      src="../assets/logo/elha-logo-green-white.svg"
      alt="ELHA logo"
      :class="sizeClass"
    />
  </figure>
</template>

<script>
export default {
  name: "Logo",

  props: {
    color: {
      default: "black",
      type: String
    },

    size: {
      default: "lg",
      type: String
    }
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        case "lg":
          return "logo--lg";

        case "sm":
          return "logo--sm";

        default:
          return "logo--lg";
      }
    }
  }
};
</script>

<style scoped>
figure {
  margin: 0;
}

.logo--sm {
  width: 75px;
}

.logo--lg {
  width: 120px;
}
</style>
