<template>
  <div id="app">
    <router-view v-if="!loading" />

    <loading-screen v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";

import LoadingScreen from "./components/LoadingScreen.vue";

export default {
  components: { LoadingScreen },

  computed: {
    ...mapState(["loading"])
  }
};
</script>
