<template>
  <div class="loading-screen">
    <logo />
    <loading-spinner />
  </div>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue";
import Logo from "./Logo.vue";

export default {
  name: "LoadingScreen",

  components: { LoadingSpinner, Logo }
};
</script>

<style scoped>
.loading-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
  background-color: white;
}
</style>
