import { usersCollection, db, functions } from "../../firebase";

const state = () => ({
  users: []
});

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },

  addUser({ users }, user) {
    users.push(user);
  },

  updateUser({ users }, user) {
    users.splice(
      users.findIndex(u => u.id === user.id),
      1,
      user
    );
  },

  deleteUser({ users }, id) {
    users.splice(
      users.findIndex(user => user.id === id),
      1
    );
  }
};

const actions = {
  async fetchUsers({ commit, rootState }) {
    const userSnaps = await usersCollection
      .where("surveyId", "==", rootState.surveyId)
      .orderBy("index")
      .get();

    const users = [];

    userSnaps.forEach(snap => users.push({ id: snap.id, ...snap.data() }));

    commit("setUsers", users);
  },

  async addUser({ commit, rootState, state }, user) {
    const maxIndex = state.users.reduce((max, { index }) => {
      if (!index) return max;
      if (index > max) return index;
      return max;
    }, 0);

    const callable = functions.httpsCallable("createUsers");

    const { data } = await callable([
      {
        filters: [],
        companyId: rootState.company.id,
        surveyId: rootState.surveyId,
        index: maxIndex + 1,
        ...user
      }
    ]);

    if (data.success) {
      commit("addUser", {
        id: data.users[0].id,
        filters: [],
        companyId: rootState.company.id,
        index: maxIndex + 1,
        ...user
      });
      return true;
    } else throw new Error(data.error.message);
  },

  async updateUser({ commit }, { id, ...user }) {
    await usersCollection.doc(id).update({ ...user });

    commit("updateUser", { id, ...user });
  },

  async deleteUser({ commit }, id) {
    await usersCollection.doc(id).delete();

    commit("deleteUser", id);
  },

  async addMultipleUsers({ commit, rootState, state }, users) {
    const maxIndex = state.users.reduce(({ index }, max) => {
      if (!index) return max;
      if (index > max) return index;
      return max;
    }, 0);

    const userObjects = users.map((u, i) => {
      return {
        companyId: rootState.company.id,
        surveyId: rootState.surveyId,
        index: maxIndex + i + 1,
        ...u
      };
    });

    const callable = functions.httpsCallable("createUsers");

    const { data } = await callable(userObjects);

    if (data.success) {
      data.users.forEach(user => commit("addUser", user));
      return true;
    } else throw new Error(data.error.message);
  },

  async batchDelete({ commit }, ids) {
    if (ids.length > 10) {
      this.setAlert({
        state: "error",
        message: "Solo puede eliminar hasta 10 usuarios a la vez"
      });
      return;
    }

    const batch = db.batch();

    ids.forEach(id => {
      batch.delete(usersCollection.doc(id));
    });

    await batch.commit();

    ids.forEach(id => commit("deleteUser", id));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
