import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/report/:surveyName",
    name: "GeneralReport",
    component: () => import("../views/GeneralReport.vue")
  },
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: "Home",
        path: "/",
        component: () => import("../views/StartMenu.vue")
      },
      {
        name: "test",
        path: "test",
        component: () => import("../views/test.vue")
      },
      {
        name: "Action Plans",
        path: "action-plans",
        component: () => import("../views/ActionPlans.vue")
      },
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("../views/Dashboard.vue")
      },
      {
        name: "Scale",
        path: "scale",
        component: () => import("../views/scale/Scale.vue")
      },
      {
        name: "Dimensions",
        path: "dimensions",
        component: () => import("../views/sections/Sections.vue")
      },
      {
        name: "eNPS",
        path: "dimensions/enps",
        component: () => import("../views/sections/eNPS.vue")
      },
      {
        name: "Releases",
        path: "releases",
        component: () => import("../views/releases/Releases.vue")
      },
      {
        name: "Hierarchy",
        path: "hierarchy",
        component: () => import("../views/hierarchy/Hierarchy.vue"),
        props: true
      },
      {
        name: "Categories",
        path: "categories",
        component: () => import("../views/Categories.vue")
      },
      {
        name: "WrittenAnswers",
        path: "written-answers",
        component: () => import("../views/writtenAnswers/WrittenAnswers.vue")
      },
      {
        name: "NewWrittenAnswer",
        path: "written-answers/new",
        component: () => import("../views/writtenAnswers/NewAnswer.vue")
      },
      {
        name: "Settings",
        path: "settings",
        component: () => import("../views/Settings.vue")
      },
      {
        name: "Users",
        path: "users",
        component: () => import("../views/users/Users.vue")
      },
      {
        name: "Preview",
        path: "/preview/survey",
        component: () => import("../views/preview/SurveyPreview.vue")
      },
      {
        name: "WelcomeMessagePreview",
        path: "/preview/welcome",
        component: () => import("../views/preview/WelcomeMessagePreview.vue")
      },
      {
        name: "ThankYouMessagePreview",
        path: "/preview/thank-you",
        component: () => import("../views/preview/ThankYouMessagePreview.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
