import * as fb from "../../firebase";

const state = () => ({
  levels: [],
  options: []
});

const getters = {
  getLevelOptions: ({ options }) => levelId =>
    options.filter(o => o.levelId === levelId)
};

const mutations = {
  setLevels(state, levels) {
    state.levels = levels;
  },

  addLevel(state, level) {
    state.levels.push(level);
  },

  deleteLevel(state, id) {
    state.levels.splice(
      state.levels.findIndex(level => level.id === id),
      1
    );
  },

  setOptions(state, options) {
    state.options = options;
  },

  addOption(state, option) {
    state.options.push(option);
  },

  editOption(state, option) {
    state.options.splice(
      state.options.findIndex(o => o.id === option.id),
      1,
      option
    );
  },

  deleteOption(state, id) {
    state.options.splice(
      state.options.findIndex(o => o.id === id),
      1
    );
  }
};

const actions = {
  async fetchHierarchy({ dispatch }) {
    await dispatch("fetchLevels");
    await dispatch("fetchOptions");
  },

  async fetchLevels({ commit, rootState }) {
    const levelSnaps = await fb
      .levelsCollection(rootState.survey.id)
      .orderBy("level")
      .get();

    let levels = [];

    levelSnaps.forEach(doc => levels.push({ id: doc.id, ...doc.data() }));

    commit("setLevels", levels);
  },

  async addLevel({ rootState, commit }, level) {
    const { id } = await fb.levelsCollection(rootState.survey.id).add(level);

    commit("addLevel", { id, ...level });
  },

  async deleteLevel({ rootState, commit, state }, id) {
    const batch = fb.db.batch();

    const levelOptions = state.options.filter(o => o.levelId === id);

    levelOptions.forEach(({ id }) => {
      batch.delete(fb.optionsCollection(rootState.survey.id).doc(id));
    });

    await batch.delete(fb.levelsCollection(rootState.survey.id).doc(id));

    await batch.commit();

    levelOptions.forEach(({ id }) => commit("deleteOption", id));

    commit("deleteLevel", id);
  },

  async addOption({ rootState, commit }, option) {
    const { id } = await fb.optionsCollection(rootState.survey.id).add(option);

    commit("addOption", { id, ...option });
  },

  async fetchOptions({ commit, rootState }) {
    const optionSnaps = await fb.optionsCollection(rootState.survey.id).get();

    const options = [];

    optionSnaps.forEach(doc => options.push({ id: doc.id, ...doc.data() }));

    commit("setOptions", options);
  },

  async addOptions({ rootState, commit }, options) {
    let batch = fb.db.batch();

    options.forEach(option => {
      const docRef = fb.optionsCollection(rootState.survey.id).doc();

      batch.set(docRef, option);

      option.id = docRef.id;
    });

    await batch.commit();

    options.forEach(option => commit("addOption", option));
  },

  async editOption({ rootState, commit }, { id, ...option }) {
    await fb
      .optionsCollection(rootState.survey.id)
      .doc(id)
      .update(option);

    commit("editOption", { id, ...option });
  },

  async deleteOption({ rootState, commit }, id) {
    await fb
      .optionsCollection(rootState.survey.id)
      .doc(id)
      .delete();

    commit("deleteOption", id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
