import {
  db,
  firestore,
  sectionsCollection,
  questionsCollection
} from "../../firebase";

const state = () => ({
  sections: [],
  questions: []
});

const mutations = {
  setSections(state, sections) {
    state.sections = sections;
  },

  setQuestions(state, questions) {
    state.questions = questions;
  },

  addSection({ sections }, section) {
    sections.push(section);
  },

  updateSectionName(state, { id, name, questionIds }) {
    const section = state.sections.find(s => s.id === id);

    if (!section) return;

    section.name = name;

    state.questions = state.questions.map(q => {
      if (questionIds.includes(q.id)) return { ...q, section: { id, name } };
      return q;
    });
  },

  deleteSection(state, id) {
    state.sections.splice(
      state.sections.findIndex(s => s.id === id),
      1
    );

    state.questions = state.questions.filter(q => q.section.id !== id);
  },

  setSectionQuestions(state, questions) {
    state.questions.push(...questions);
  },

  addQuestions(state, { sectionId, questions }) {
    state.questions.push(...questions);

    const section = state.sections.find(s => s.id === sectionId);

    section.questionIds.push(...questions.map(q => q.id));
  },

  deleteQuestion(state, { id, sectionId }) {
    state.questions.splice(
      state.questions.findIndex(q => q.id === id),
      1
    );

    const section = state.sections.find(s => s.id === sectionId);

    section.questionIds.splice(section.questionIds.indexOf(id), 1);
  },

  updateQuestion(state, { id, text }) {
    const question = state.questions.find(q => q.id === id);

    question.text = text;
  },

  updateQuestionsOrder(state, questions) {
    state.questions = state.questions
      .filter(q => q.section.id !== "enps")
      .map(q => {
        const newIndex = questions.find(({ id }) => q.id === id).index;

        return { ...q, index: newIndex };
      })
      .sort((a, b) => b.index - a.index);
  }
};

const actions = {
  async fetchSections({ commit, rootState }) {
    const snapshot = await sectionsCollection(rootState.surveyId).get();

    const sections = [];

    snapshot.forEach(doc => sections.push({ id: doc.id, ...doc.data() }));
    const enps = sections.splice(
      sections.findIndex(s => s.id === "enps"),
      1
    );

    sections.unshift(...enps);

    commit("setSections", sections);
  },

  async fetchQuestions({ commit, rootState }) {
    const questionSnaps = await questionsCollection(rootState.surveyId)
      .orderBy("index")
      .get();

    const questions = [];

    questionSnaps.forEach(snap => {
      if (
        snap.data().section.id === "enps"
          ? rootState.survey.isEnpsEnabled
          : true
      )
        questions.push({ id: snap.id, ...snap.data() });
    });

    commit("setQuestions", questions);
  },

  async fetchSection({ commit, dispatch, rootState }, id) {
    const doc = await sectionsCollection(rootState.surveyId)
      .doc(id)
      .get();

    commit("addSection", { id, questions: [], ...doc.data() });

    await dispatch("fetchSectionQuestions", id);
  },

  async fetchSectionQuestions({ commit, rootState }, id) {
    const questionRefs = await questionsCollection(rootState.surveyId)
      .where("section.id", "==", id)
      .orderBy("index")
      .get();

    const questions = [];

    questionRefs.forEach(doc => questions.push({ id: doc.id, ...doc.data() }));

    commit("setSectionQuestions", questions);
  },

  async addSection({ commit, rootState }, name) {
    const { id } = await sectionsCollection(rootState.surveyId).add({
      name,
      questionIds: []
    });

    commit("addSection", { id, name, questionIds: [], isEditable: true });

    return id;
  },

  async updateSectionName({ commit, state, rootState }, { id, name }) {
    const batch = db.batch();

    batch.update(sectionsCollection(rootState.survey.id).doc(id), { name });

    const section = state.sections.find(s => s.id === id);

    section.questionIds.forEach(qid => {
      batch.update(questionsCollection(rootState.survey.id).doc(qid), {
        section: { id, name }
      });
    });

    await batch.commit();

    commit("updateSectionName", { id, name, questionIds: section.questionIds });
  },

  async deleteSection({ commit, rootState }, { id, questionIds }) {
    const batch = db.batch();

    questionIds.forEach(id => {
      batch.delete(questionsCollection(rootState.surveyId).doc(id));
    });

    batch.delete(sectionsCollection(rootState.surveyId).doc(id));

    await batch.commit();

    commit("deleteSection", id);
  },

  async addQuestions({ state, commit, rootState }, { questions, section }) {
    const batch = db.batch();

    let questionRef;

    const startingIndex = state.sections.reduce(
      (t, s) => (s.isEditable ? s.questionIds.length + t : t),
      0
    );

    const questionObjects = questions.map((question, index) => {
      questionRef = questionsCollection(rootState.surveyId).doc();

      batch.set(questionRef, {
        ...question,
        index: startingIndex + index,
        section
      });

      return {
        ...question,
        id: questionRef.id,
        index: startingIndex + index,
        section
      };
    });

    const sectionRef = sectionsCollection(rootState.surveyId).doc(section.id);

    batch.update(sectionRef, {
      questionIds: firestore.FieldValue.arrayUnion(
        ...questionObjects.map(q => q.id)
      )
    });

    await batch.commit();

    commit("addQuestions", {
      sectionId: section.id,
      questions: questionObjects
    });
  },

  async deleteQuestion({ commit, rootState }, { id, sectionId }) {
    const batch = db.batch();

    batch.update(sectionsCollection(rootState.survey.id).doc(sectionId), {
      questionIds: firestore.FieldValue.arrayRemove(id)
    });

    batch.delete(questionsCollection(rootState.survey.id).doc(id));

    await batch.commit();

    commit("deleteQuestion", { id, sectionId });
  },

  async updateQuestion({ commit, rootState }, { id, text, isOpenQuestion }) {
    await questionsCollection(rootState.survey.id)
      .doc(id)
      .update({ text, isOpenQuestion });

    commit("updateQuestion", { id, text });
  },

  async updateQuestionsOrder({ commit, rootState }, questions) {
    const batch = db.batch();

    questions.forEach(({ id, index }) => {
      batch.update(questionsCollection(rootState.survey.id).doc(id), { index });
    });

    await batch.commit();

    commit("updateQuestionsOrder", questions);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
