import { scaleCollection, db } from "../../firebase";

const state = () => ({
  scale: []
});

const mutations = {
  addOption(state, option) {
    state.scale.push(option);
  },

  deleteOption(state, id) {
    state.scale.splice(
      state.scale.findIndex(o => o.id === id),
      1
    );
  },

  updateOption(state, option) {
    state.scale.splice(
      state.scale.findIndex(o => o.id === option.id),
      1,
      option
    );
  },

  setScale(state, scale) {
    state.scale = scale;
  },

  updateOrder(state, scale) {
    state.scale = state.scale
      .map(option => ({
        ...option,
        order: (scale.find(o => o.id === option.id) || option).order
      }))
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }
};

const actions = {
  async fetchScale({ commit, rootState }) {
    const optionSnaps = await scaleCollection(rootState.surveyId)
      .orderBy("order")
      .get();

    const scale = [];

    optionSnaps.forEach(snap => {
      scale.push({ id: snap.id, ...snap.data() });
    });

    commit("setScale", scale);

    return scale;
  },

  async replaceScale({ commit, dispatch, rootState }, scale) {
    const oldOptions = await dispatch("fetchScale");

    const batch = db.batch();

    oldOptions.forEach(o => {
      batch.delete(scaleCollection(rootState.surveyId).doc(o.id));
    });

    let optionRef;
    const newScale = [];
    scale.forEach(option => {
      optionRef = scaleCollection(rootState.surveyId).doc();
      batch.set(optionRef, option);
      newScale.push({ id: optionRef.id, ...option });
    });

    await batch.commit();

    commit("setScale", newScale);
  },

  async addOption({ commit, rootState }, option) {
    const { id } = await scaleCollection(rootState.surveyId).add(option);

    commit("addOption", { id, ...option });
  },

  async deleteOption({ commit, rootState, state }, { id, order }) {
    const batch = db.batch();

    batch.delete(scaleCollection(rootState.surveyId).doc(id));

    const optionsToUpdate = state.scale
      .filter(o => o.order > order)
      .map(o => ({ id: o.id, order: o.order }));

    optionsToUpdate.forEach(o => {
      batch.update(scaleCollection(rootState.surveyId).doc(o.id), {
        order: o.order - 1
      });
    });

    await batch.commit();

    commit("deleteOption", id);
    commit("updateOrder", optionsToUpdate);
  },

  async updateOption({ commit, rootState }, { id, ...option }) {
    await scaleCollection(rootState.surveyId)
      .doc(id)
      .set(option);

    commit("updateOption", { id, ...option });
  },

  async updateOrder({ commit, rootState }, scale) {
    // scale is an array of objects with id and order properties
    const batch = db.batch();

    let optionRef;
    scale.forEach(o => {
      optionRef = scaleCollection(rootState.surveyId).doc(o.id);
      batch.update(optionRef, {
        order: o.order
      });
    });

    await batch.commit();

    commit("updateOrder", scale);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
