import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";
import sections from "./modules/sections";
import users from "./modules/users";
import hierarchy from "./modules/hierarchy";
import scale from "./modules/scale";
import filters from "./modules/filters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sections,
    users,
    hierarchy,
    scale,
    filters
  },

  state: {
    userProfile: {
      id: ""
    },

    company: {},

    survey: {
      instructions: "",
      welcomeMessage: "",
      goodbyeMessage: "",
      emailMessage: "",
      isEnpsEnabled: true
    },

    surveyId: "",

    surveys: [],

    answers: [],

    writtenAnswers: [],

    actionPlans: [],

    categories: [],

    alert: {
      state: "",
      message: ""
    },

    loading: false,

    navIsOpen: true
  },

  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },

    toggleNav(state) {
      state.navIsOpen = !state.navIsOpen;
    },

    setCompany(state, val) {
      state.company = val;
    },

    setCurrentSurvey(state, val) {
      state.survey = val;
      state.surveyId = val.id;
    },

    setSurveys(state, surveys) {
      state.surveys = surveys;
    },

    activateSurvey(state) {
      state.survey.active = true;
    },

    setLogo(state, logoURL) {
      state.company.logoURL = logoURL;
    },

    setWelcomeMessagePicture(state, pictureURL) {
      state.survey.welcomeMessagePictureURL = pictureURL;
    },

    updateUserProfile(state, val) {
      const newUserProfile = {
        ...state.userProfile,
        ...val
      };

      state.userProfile = newUserProfile;

      localStorage.setItem("userProfile", JSON.stringify(newUserProfile));
    },

    setAnswers(state, answers) {
      state.answers = answers;
    },

    setWrittenAnswers(state, answers) {
      state.writtenAnswers = answers;
    },

    addWrittenAnswer(state, answer) {
      state.writtenAnswers.push(answer);
    },

    deleteWrittenAnswer(state, id) {
      state.writtenAnswers.splice(
        state.writtenAnswers.findIndex(a => a.id === id),
        1
      );
    },

    addActionPlan(state, actionPlan) {
      state.actionPlans.push(actionPlan);
    },

    setActionPlans(state, actionPlans) {
      state.actionPlans = actionPlans;
    },

    deleteActionPlan(state, actionPlanID) {
      state.actionPlans = state.actionPlans.filter(
        actionPlan => actionPlan.id !== actionPlanID
      );
    },

    editActionPlan(state, actionPlan) {
      state.actionPlans = state.actionPlans.map(el =>
        el.id === actionPlan.id ? { ...el, ...actionPlan } : el
      );
    },

    setAlert({ alert }, newAlert) {
      Object.assign(alert, newAlert);
    },

    setLoadingState(state, isLoading) {
      state.loading = isLoading;
    },

    updateWelcomeMessage(state, message) {
      state.survey.welcomeMessage = message;
    },

    updateEmailSubject(state, text) {
      state.survey.subject = text;
    },

    updateGoodbyeMessageText(state, text) {
      state.survey.goodbyeMessageText = text;
    },

    updateGoodbyeMessageTitle(state, title) {
      state.survey.goodbyeMessageTitle = title;
    },

    updateEmailMessage(state, text) {
      state.survey.emailMessage = text;
    },

    updateSignature(state, text) {
      state.survey.signatureText = text;
    },

    updateSentEmails(state, sentEmails) {
      state.survey.sentEmails = sentEmails;
    },

    updateSurveyLimits(state, limits) {
      state.survey.limits = limits;
    },

    updateParticipationData(state, data) {
      state.survey.data = data;
    },

    updateSurveyColors(state, colors) {
      state.survey.colors = colors;
    },

    updateCompanyColor(state, color) {
      state.company.color = color;
    },

    updateActionPlanColors(state, actionPlanColors) {
      state.survey.actionPlanColors = actionPlanColors;
    },

    updateEnpsActivation(state, isEnpsEnabled) {
      state.survey.isEnpsEnabled = isEnpsEnabled;
    }
  },

  actions: {
    async login(context, { email, password }) {
      await fb.auth.signInWithEmailAndPassword(email, password);
      router.push("/");
    },

    async logout({ commit }) {
      await fb.auth.signOut();

      commit("setUserProfile", {});
      commit("setCompany", {});
      commit("setCurrentSurvey", {});
      commit("setActionPlans", []);

      router.push("/");
    },

    async fetchUserProfile({ commit }, uid) {
      const userSnapshot = await fb.usersCollection.doc(uid).get();

      const userProfile = {
        id: userSnapshot.id,
        ...userSnapshot.data()
      };

      commit("setUserProfile", userProfile);
    },

    async fetchCompany({ commit }, companyID) {
      const companySnapshot = await fb.companiesCollection.doc(companyID).get();

      const company = {
        id: companySnapshot.id,
        ...companySnapshot.data()
      };

      commit("setCompany", company);
    },

    async fetchCurrentSurvey({ commit }, companyId) {
      const querySnapshot = await fb.surveysCollection
        .where("companyId", "==", companyId)
        .where("current", "==", true)
        .get();

      let survey;

      querySnapshot.forEach(doc => {
        survey = {
          userTableFields: [],
          scale: [],
          id: doc.id,
          ...doc.data()
        };
      });

      commit("setCurrentSurvey", survey);
    },

    async fetchAllSurveys({ commit }, companyId) {
      const surveySnaps = await fb.surveysCollection
        .where("companyId", "==", companyId)
        .get();

      const surveys = [];

      surveySnaps.forEach(doc => surveys.push({ id: doc.id, ...doc.data() }));

      commit("setSurveys", surveys);
    },

    async fetchUserData({ state, dispatch, commit }, user) {
      commit("setLoadingState", true);

      await dispatch("fetchUserProfile", user.uid);

      if (!state.userProfile.admin) {
        await dispatch("logout");
        commit("setLoadingState", false);
        return;
      }

      await dispatch("fetchCompany", state.userProfile.companyId);

      await dispatch("fetchCurrentSurvey", state.company.id);

      commit("setLoadingState", false);
    },

    async fetchAllAnswers({ state, commit }) {
      const answerSnaps = await fb.answersCollection(state.survey.id).get();

      const answers = [];

      answerSnaps.forEach(doc => {
        answers.push({
          id: doc.id,
          ...doc.data()
        });
      });

      commit("setAnswers", answers);
    },

    async uploadWelcomeMessagePicture({ state, commit }, picture) {
      const uploadTask = await fb.storage
        .ref(`${state.company.id}/welcomeMessagePic`)
        .put(picture);

      const welcomeMessagePictureURL = await uploadTask.ref.getDownloadURL();

      await fb.surveysCollection
        .doc(state.surveyId)
        .update({ welcomeMessagePictureURL });

      commit("setWelcomeMessagePicture", welcomeMessagePictureURL);
    },

    async deleteWelcomeMessagePicture({ state, commit }) {
      const wmPicURL = state.survey.welcomeMessagePictureURL;

      if (!wmPicURL) return;

      await fb.storage.refFromURL(wmPicURL).delete();

      await fb.surveysCollection
        .doc(state.surveyId)
        .update({ welcomeMessagePictureURL: "" });

      commit("setWelcomeMessagePicture", "");
    },

    async uploadLogo({ state, commit }, logo) {
      const uploadTask = await fb.storage
        .ref(`${state.company.id}/logo`)
        .put(logo);

      const logoURL = await uploadTask.ref.getDownloadURL();

      console.log(logoURL);

      await fb.companiesCollection.doc(state.company.id).update({ logoURL });

      commit("setLogo", logoURL);
    },

    async deleteLogo({ state, commit }) {
      const logoURL = state.company.logoURL;

      if (!logoURL) return;

      await fb.storage.refFromURL(logoURL).delete();

      await fb.companiesCollection
        .doc(state.company.id)
        .update({ logoURL: "" });

      commit("setLogo", "");
    },

    async updateWelcomeMessage({ state, commit }, welcomeMessage) {
      await fb.surveysCollection
        .doc(state.survey.id)
        .update({ welcomeMessage });

      commit("updateWelcomeMessage", welcomeMessage);
    },

    async updateEmailSubject({ state, commit }, emailSubject) {
      await fb.surveysCollection.doc(state.survey.id).update({ subject: emailSubject });

      commit("updateEmailSubject", emailSubject);
    },

    async updateEmailMessage({ state, commit }, emailMessage) {
      await fb.surveysCollection.doc(state.survey.id).update({ emailMessage });

      commit("updateEmailMessage", emailMessage);
    },

    async updateSignature({ state, commit }, signatureText) {
      await fb.surveysCollection.doc(state.survey.id).update({ signatureText });

      commit("updateSignature", signatureText);
    },

    async updateGoodbyeMessageText({ state, commit }, text) {
      await fb.surveysCollection
        .doc(state.survey.id)
        .update({ goodbyeMessageText: text });

      commit("updateGoodbyeMessageText", text);
    },

    async updateGoodbyeMessageTitle({ state, commit }, title) {
      await fb.surveysCollection
        .doc(state.survey.id)
        .update({ goodbyeMessageTitle: title });

      commit("updateGoodbyeMessageTitle", title);
    },

    async fetchCategories({ state, commit }) {
      const categorySnaps = await fb
        .categoriesCollection(state.survey.id)
        .get();

      const categories = [];

      categorySnaps.forEach(doc => {
        categories.push({ id: doc.id, ...doc.data() });
      });

      commit("setCategories", categories);
    },

    async addCategory({ state, commit }, category) {
      const { id } = fb.categoriesCollection(state.survey.id).add(category);

      commit("addCategory", { id, ...category });
    },

    deleteCategory({ state, commit }, id) {
      fb.categoriesCollection(state.survey.id)
        .doc(id)
        .delete();

      commit("deleteCategory", id);
    },

    updateCategory({ state, commit }, { id, ...category }) {
      fb.categoriesCollection(state.survey.id)
        .doc(id)
        .set(category);

      commit("updateCategory", { id, ...category });
    },

    async fetchActionPlans({ state, commit }) {
      let snapshot;
      if (state.userProfile.isAP)
        snapshot = await fb.actionPlansCollection
          .where("companyId", "==", state.company.id)
          .get();
      else
        snapshot = await fb.actionPlansCollection
          .where("companyId", "==", state.company.id)
          .where("userId", "==", state.userProfile.id)
          .get();
      console.log(snapshot);
      const actionPlans = [];
      snapshot.forEach(doc => {
        actionPlans.push({ id: doc.id, ...doc.data() });
      });

      commit("setActionPlans", actionPlans);
    },

    addActionPlan({ state, commit }, actionPlan) {
      return fb.actionPlansCollection
        .add({
          userId: state.userProfile.id,
          companyId: state.company.id,
          ...actionPlan
        })
        .then(doc => {
          commit("addActionPlan", {
            id: doc.id,
            ...actionPlan
          });
        });
    },

    async deleteActionPlan({ commit }, { actionPlanID, fileName }) {
      if (fileName)
        await fb.storage.ref(`${actionPlanID}/${fileName}`).delete();

      return fb.actionPlansCollection
        .doc(actionPlanID)
        .delete()
        .then(() => commit("deleteActionPlan", actionPlanID));
    },

    async editActionPlan({ commit }, { id, ...actionPlan }) {
      await fb.actionPlansCollection.doc(id).update(actionPlan);
      commit("editActionPlan", { id, ...actionPlan });
    },

    async sendBatchEmail({ commit, state }, users) {
      const callable = fb.functions.httpsCallable("batchEmail");

      const { data } = await callable({
        surveyId: state.survey.id,
        users
      });

      console.log(data);

      if (data.success) {
        commit("updateSentEmails", data.sentEmails);
        return true;
      } else throw new Error(data.error.message);
    },

    async getGeneralReportData(context, { surveyId }) {
      const callable = fb.functions.httpsCallable("getGeneralReportData");
      console.log(surveyId);

      const { data } = await callable({
        surveyId
      });

      return data;
    },

    async getDashboardData(
      context,
      {
        surveyId,
        selectedDisplay,
        categories,
        selectedCategory,
        filters,
        selectedDimensions
      }
    ) {
      const callable = fb.functions.httpsCallable("getDashboardData");

      const { data } = await callable({
        surveyId,
        selectedDisplay,
        categories,
        selectedCategory,
        filters,
        selectedDimensions
      });

      return data;
    },

    async getParticipationData(context, { surveyId, categories }) {
      const callable = fb.functions.httpsCallable("getParticipationData");

      const { data } = await callable({
        surveyId,
        categories
      });

      return data;
    },

    async sendActionPlanEmail({ state }, { email, title, actionPlan }) {
      const callable = fb.functions.httpsCallable("sendActionPlan");

      return await callable({
        email,
        title,
        actionPlan,
        surveyId: state.survey.id
      });
    },

    async addWrittenAnswer({ commit, state }, { features, answers }) {
      const createdAt = new Date();

      const {
        multipleChoiceAnswers,
        enpsAnswer,
        engagementAnswers,
        openAnswers
      } = answers.reduce(
        (obj, a) => {
          if (typeof a.answer === "string") {
            return { ...obj, openAnswers: [...obj.openAnswers, a] };
          }
          if (a.section.id === "engagement") {
            return { ...obj, engagementAnswers: [...obj.engagementAnswers, a] };
          }
          if (a.section.id === "enps") {
            return { ...obj, enpsAnswer: a };
          }
          return {
            ...obj,
            multipleChoiceAnswers: [...obj.multipleChoiceAnswers, a]
          };
        },
        {
          multipleChoiceAnswers: [],
          enpsAnswer: {},
          engagementAnswers: [],
          openAnswers: []
        }
      );

      const { id } = await fb.answersCollection(state.surveyId).add({
        features,
        multipleChoiceAnswers,
        enpsAnswer,
        engagementAnswers,
        openAnswers,
        isWrittenAnswer: true,
        createdAt: fb.firestore.Timestamp.fromDate(createdAt)
      });

      commit("addWrittenAnswer", {
        id,
        features,
        answers,
        isWrittenAnswer: true,
        createdAt
      });
    },

    async fetchWrittenAnswers({ commit, state }) {
      const answerSnaps = await fb
        .answersCollection(state.surveyId)
        .where("isWrittenAnswer", "==", true)
        .orderBy("createdAt")
        .get();

      const writtenAnswers = [];

      let answers;
      answerSnaps.forEach(snap => {
        answers = [
          ...(snap.data().multipleChoiceAnswers || []),
          ...(snap.data().openAnswers || []),
          ...(snap.data().engagementAnswers || []),
          snap.data().enpsAnswer || []
        ];

        answers = answers.sort((a, b) => a.index - b.index);

        writtenAnswers.push({
          id: snap.id,
          ...snap.data(),
          answers
        });
      });

      commit("setWrittenAnswers", writtenAnswers);
    },

    async deleteWrittenAnswer({ commit, state }, id) {
      await fb
        .answersCollection(state.surveyId)
        .doc(id)
        .delete();

      commit("deleteWrittenAnswer", id);
    },

    async updatePassword(context, newPassword) {
      await fb.auth.currentUser.updatePassword(newPassword);
    },

    async activateSurvey({ commit, state }, endDate) {
      const batch = fb.db.batch();

      batch.update(fb.surveysCollection.doc(state.survey.id), {
        active: true,
        endDate
      });

      batch.update(fb.companiesCollection.doc(state.company.id), {
        activeSurveyId: state.survey.id
      });

      await batch.commit();

      commit("activateSurvey");
    },

    async uploadActionPlanFile(context, { file, id }) {
      await fb.storage.ref(`${id}/${file.name}`).put(file);
    },

    async getFile(context, id) {
      let fileRef;
      const storageRef = fb.storage.ref(`${id}/`);
      await storageRef.listAll().then(result => {
        fileRef = result.items[0];
      });
      return fileRef;
    },

    async updateSurveyLimits({ state, commit }, limits) {
      await fb.surveysCollection.doc(state.survey.id).update({ limits });

      commit("updateSurveyLimits", limits);
    },

    async updateParticipationData({ state, commit }, participationData) {
      await fb.surveysCollection
        .doc(state.survey.id)
        .update({ participationData });

      commit("updateParticipationData", participationData);
    },

    async updateSurveyColors({ state, commit }, colors) {
      await fb.surveysCollection.doc(state.survey.id).update({ colors });

      commit("updateSurveyColors", colors);
    },

    async updateCompanyColor({ state, commit }, color) {
      await fb.companiesCollection.doc(state.company.id).update({ color });

      commit("updateCompanyColor", color);
    },

    async updateActionPlanColors({ state, commit }, actionPlanColors) {
      await fb.surveysCollection
        .doc(state.survey.id)
        .update({ actionPlanColors });

      commit("updateActionPlanColors", actionPlanColors);
    },

    async updateEnpsActivation({ commit, state }, isEnpsEnabled) {
      await fb.surveysCollection.doc(state.surveyId).update({ isEnpsEnabled });

      commit("updateEnpsActivation", isEnpsEnabled);
    }
  }
});
