import { filtersCollection, firestore } from "../../firebase";

const state = () => ({
  filters: []
});

const mutations = {
  setFilters(state, filters) {
    state.filters = filters;
  },

  addFilter(state, filter) {
    state.filters.push(filter);
  },

  updateFilter(state, filter) {
    state.filters.splice(
      state.filters.findIndex(f => f.id === filter.id),
      1,
      filter
    );
  },

  deleteFilter(state, id) {
    state.filters.splice(
      state.filters.findIndex(f => f.id === id),
      1
    );
  }
};

const actions = {
  async fetchFilters({ commit, rootState }) {
    const filterSnaps = await filtersCollection(rootState.surveyId)
      .orderBy("createdAt")
      .get();

    const filters = [];

    filterSnaps.forEach(snap => filters.push({ id: snap.id, ...snap.data() }));

    commit("setFilters", filters);
  },

  async addFilter({ commit, rootState }, filter) {
    const createdAt = firestore.FieldValue.serverTimestamp();

    const { id } = await filtersCollection(rootState.surveyId).add({
      createdAt,
      ...filter
    });

    commit("addFilter", { id, createdAt, ...filter });
  },

  async updateFilter({ commit, rootState }, { id, ...filter }) {
    await filtersCollection(rootState.surveyId)
      .doc(id)
      .update({ ...filter });

    commit("updateFilter", { id, ...filter });
  },

  async deleteFilter({ commit, rootState }, id) {
    await filtersCollection(rootState.surveyId)
      .doc(id)
      .delete();

    commit("deleteFilter", id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
